import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div className="mw9 center pa3">
      <h1>Not found...</h1>
    </div>
  </Layout>
)

export default NotFoundPage
